/* eslint-disable max-len */

export const colors = {
  // TODO: remove old color palette
  // primary
  orange: "#BA4637",
  brownDark: "#332321",
  brownLite: "#564745",
  maroon: "#680F1C",
  lime: "#EAFD00",

  // secondary
  pink: "#C96D72",
  peach: "#F09F89",
  pinkLite: "#FED1C5",
  pastel: "#A35257",

  // neutral
  smoke: "#30353F",
  silver: "#D4D4D4",
  cream: "#F1EDE5",

  // ---------------

  // new primary
  candy: "#E60062",
  gold: "#E5C77F",

  // new secondary
  periwinkle: "#6F77AB",
  petricite: " #171610",
  purple: "#1D2241",
  purpleLight: "#353D6F",
  greenDark: "#00725A",
  greyGreen: "#394B59",

  // tertiary
  steam: "#353D6F",
  toxicant: "#00B38D",
  haze: "#54918E",
  zaunGray: "#8EBDBB",

  // new neutral
  black: "#000000",
  white: "#FFFFFF",
  gray: "#E5E5E3",
  darkGray: "#3D3D3D"
};

export const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px",
};

export const eases = {
  inOut: "cubic-bezier(0.75, 0, 0.25, 1)",
  out: "cubic-bezier(0.0, 0.75, 0.25, 1)",
  in: "cubic-bezier(0.75, 0.0, 1, 0.25)",
};

export const zIndices = {
  hideBehind: -1,
  lightningClip: 1,
  championImg: 2,
  futureEkko: 2,
  swirl: 3,
  swoosh: 4,
  bgGradient: 5,
  videoOverlay: 999999, // must be high enough to appear above Riotbar
  ekko: 6,
};

export const spacing = {
  base: "16px",
  sectionPaddingMobile: "64px",
  sectionPaddingTablet: "88px",
  sectionPaddingLaptop: "128px",
};
