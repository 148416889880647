import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/variables";

const StyledGrid = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1440px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-template-rows: auto;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 3vw;
  }

  @media (min-width: calc(${breakpoints.laptop} + 64px)) {
    padding: 0;
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    padding: 0 24px;
  }
`;
interface Props {
  className?: any;
}

export default class GridContainer extends React.Component<Props> {
  render() {
    return <StyledGrid className={this.props.className}>{this.props.children}</StyledGrid>;
  }
}
